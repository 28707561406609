import axios from "axios";
import {
  BASE_URL,
  CLIENT_SERVICE_PORT,
  REQUESTS_SERVICE_PORT,
  FEEDBACK_SERVICE_PORT,
  MESSAGES_SERVICE_PORT
} from "@/config";

import store from "@/store";

export const ClientAuthService = axios.create({
  baseURL: `${BASE_URL}${CLIENT_SERVICE_PORT}/v1`,
  cors: false
});

export const ClientLogoutService = () =>
  makeAuthedService("v1", CLIENT_SERVICE_PORT);

export const ClientService = () => makeAuthedService("v1", CLIENT_SERVICE_PORT);

export const FeedbackService = () =>
  makeAuthedService("v1", FEEDBACK_SERVICE_PORT);

export const RequestService = () =>
  makeAuthedService("v1", REQUESTS_SERVICE_PORT);

export const MessageService = () =>
  makeAuthedService("v1", MESSAGES_SERVICE_PORT);

function makeAuthedService(path, port) {
  if (store.getters.token) {
    return axios.create({
      baseURL: `${BASE_URL}${port}/${path}`,
      headers: {
        Authorization: `Bearer ${store.getters.token}`
      }
    });
  } else {
    return axios.create({
      baseURL: `${BASE_URL}${port}/${path}`
    });
  }
}
